/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter, faTwitch, faInstagram, faTiktok, faDiscord, faYoutube } from "@fortawesome/free-brands-svg-icons"

import Header from "./header"

const Layout = ({ children }) => {
  const data = useStaticQuery(query);

  const social = data.strapiSocialMedia.data.attributes;

  const seo = data.strapiSeo.data.attributes;

  return (
    <div className="bg-black">
      <Header siteTitle={seo.Title} siteDescription={seo.Description}/>
      <div>
        <main>{children}</main>
        <footer className="bg-black text-white py-20">
          <div className="container mx-auto text-center">
          <div className=" mb-5">
            <a href={social.Twitter} target="_blank" className="text-white mx-5 text-xl"><FontAwesomeIcon icon={faTwitter} className="text-xl"/></a>
            <a href={social.Instagram} target="_blank" className="text-white mx-5 text-xl"><FontAwesomeIcon icon={faInstagram}/></a>
            <a href={social.Twitch} target="_blank" className="text-white mx-5 text-xl"><FontAwesomeIcon icon={faTwitch}/></a>
            <a href={social.TikTok} target="_blank" className="text-white mx-5 text-xl"><FontAwesomeIcon icon={faTiktok}/></a>
            <a href={social.Discord} target="_blank" className="text-white mx-5 text-xl"><FontAwesomeIcon icon={faDiscord}/></a>
            <a href="https://www.youtube.com/@mondaynitroparty" target="_blank" className="text-white mx-5 text-xl"><FontAwesomeIcon icon={faYoutube}/></a>
          </div>
          © {new Date().getFullYear()}, Josh Alba
          </div>
        </footer>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

const query = graphql`
query LayoutQuery {
  strapiSocialMedia {
    data {
      attributes {
        Facebook
        Instagram
        Twitch
        Twitter
        TikTok
        Discord
      }
    }
  }
  strapiSeo {
    data {
      attributes {
        Title
        Description
      }
    }
  }
}
`;

export default Layout
